@import 'exports.module';
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap');

html {
	scroll-behavior: smooth;
	/* GLOBAL STYLING */
	* {
		padding: 0;
		margin: 0;
		//! debug ghost elements
		// background: #000 !important;
		// color: #0f0 !important;
		// outline: solid #f00 1px !important;
	}
	overflow: scroll;
	overflow-x: hidden;

	::-webkit-scrollbar {
		width: 0; /* Remove scrollbar space */
		background: transparent; /* Optional: just make scrollbar invisible */
	}

	*:focus {
		outline: 0;
	}

	a {
		text-decoration: none;
	}

	/* BODY STYLING */
	body {
		font-family: 'Ubuntu', sans-serif;
		background: $background;
	}
}
